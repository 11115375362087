import React from 'react'
import PropTypes from 'prop-types'

import './button.scss'

const Button = ({ title, action }) => (
  <button
    className="button"
    onClick={action}
  >
    { title }
  </button>
)

Button.propTypes = {
  title: PropTypes.string,
  action: PropTypes.func,
}

Button.defaultProps = {
  title: '',
  action: null,
}

export default Button
