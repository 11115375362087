import React from 'react'
import { Link } from 'gatsby'

import './header.scss'
import Button from './button'
import navLogo from '../images/AVH-logo.svg'

const Header = () => (
  <div id="pageHeader" >
    <div style={{ width: '100px' }}>
      <Link to="/">
        <img style={{ marginBottom: 0 }} src={navLogo} alt="navigation logo" />
      </Link>
    </div>

    <nav>
      <Link to="/resume/" activeClassName="active">Resume</Link>
      <Link to="/html5elTest/" activeClassName="active">Test Page</Link>
      <Link to="/two/" activeClassName="active">Three</Link>
      <Link to="/contact/" activeClassName="active">
        <Button title="Contact" />
      </Link>
    </nav>
  </div>
)

export default Header
